import * as React from "react";

import { Helmet } from "react-helmet";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import { HtmlPageAttributes } from "../api";
import Grid47 from "../components/Grid.tsx/Grid47";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import SectionTitle from "../components/Text/SectionTitle";
import Komittees from "../components/Komittees";

interface PageTemplateProps {
  pageContext: {
    attributes: HtmlPageAttributes;
  };
}

const PageKomittee: React.FC<PageTemplateProps> = (props) => {
  const {
    pageContext: {
      attributes: { content, name, teaser, description, keywords, slug },
    },
  } = props;

  return (
    <DefaultLayout siteSlug={slug}>
      <Helmet>
        <title>{name.slice(3)} - STEP</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Page>
        <ContentWrapper>
          <SectionTitle>{name.slice(3)}</SectionTitle>
        </ContentWrapper>
        <Container>
          <Grid47
            left={
              <div>
                {teaser && <div dangerouslySetInnerHTML={{ __html: teaser }} />}
              </div>
            }
            right={
              content && <div dangerouslySetInnerHTML={{ __html: content }} />
            }
          />
        </Container>
        {typeof window !== "undefined" &&
          window.location.href.includes("centres") && (
            <ContentWrapper>
              <br />
              <br />
              <br />
              <Komittees />
            </ContentWrapper>
          )}
      </Page>
    </DefaultLayout>
  );
};

export default PageKomittee;
