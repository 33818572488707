import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { InhaltElem } from "../Text/SmallerInhalt";
import ContentWrapper from "../Wrappers/ContentWrapper";
import TextContentWrapper from "../Wrappers/TextContentWrapper";

const ItemElem = styled.article`
  width: 100%;
  text-align: center;
  margin-bottom: 46px;

  a {
    text-decoration: none;
  }

  .image {
    width: 120px;
    height: 120px;
    margin: 0 auto 12px;
  }

  h5 {
    font: 18px / 24px "Slate Medium";
    margin-bottom: 6px;
  }

  p {
    font: 18px / 22px "Slate Light";
    margin-bottom: 6px;
  }

  .more {
    margin-bottom: 6px;
    font: 14px / 17px "Slate Light";
    color: ${colors.lightBlue};
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    width: 33.3%;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 33.3%;
  }
`;

const Komittee = ({ imgurl, komitteeTitle, urlPath }) => {
  return (
    <ItemElem>
      <div
        className="image"
        style={{
          background: `url(${imgurl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {komitteeTitle && (
        <InhaltElem dangerouslySetInnerHTML={{ __html: komitteeTitle }} />
      )}
      <a href={urlPath} className="more">
        More details
      </a>
    </ItemElem>
  );
  return null;
};

export default Komittee;
